<template>
  <div class="pageContol listWrap templateList" id="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">首页</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a flexdc">
            <el-row class="t_1">
              <el-col class="t_1_1" :span="8">
                <div class="shell flexdc">
                  <div class="title_1">考试情况统计</div>
                  <div class="sxn flex1">
                    <el-select v-model="value1" placeholder="请选择" size="small" @change="get_examSituation" style="width: 120px !important;">
                      <el-option v-for="item in list1" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                    <div class="df">
                      <div id="echarts_1" style="width: 33.333%; height:168px"></div>
                      <div id="echarts_2" style="width: 33.333%; height:168px"></div>
                      <div id="echarts_3" style="width: 33.333%; height:168px"></div>
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col class="t_1_2" :span="8">
                <div class="shell flexdc">
                  <div class="title_1">各作业类别合格人数统计</div>
                  <div class="sxn flex1">
                    <el-select v-model="value2" placeholder="请选择" size="small" @change="get_statisticsOfQualifiedPersonnelForEachHomeworkCategory" style="width: 120px !important;">
                      <el-option v-for="item in list2" :key="item.value" :label="item.label" :value="item.value">
                      </el-option>
                    </el-select>
                    <div id="echarts_4" style="width: 100%; height:168px"></div>
                  </div>
                </div>
              </el-col>
              <el-col class="t_1_3" :span="8">
                <div class="shell">
                  <div class="title_1">当日考试信息</div>
                  <el-row class="sxn flex1">
                    <el-col class="t_1_3_1" :span="12">
                      <div class="dw_f t_1_3_1 flexca">
                        <img class="tt_1" src="@/assets/practicalAssessmentSystem/home_1.png" alt="">
                        <div class="tt_2 flexdc flexjc">
                          <div class="tt_2_1">计划人数</div>
                          <div class="tt_2_2">{{ examInformationForTheDay.planNum }}</div>
                        </div>
                      </div>
                      <div class="dw_c" v-if="examInformationForTheDay.planNum > 0">
                        <div class="flexcb flexcc" v-for="(item, index) in examInformationForTheDay.planDetail"
                          :key="index">
                          <span>
                            <span class="dw_c_1 ts_1"></span>
                            <span class="dw_c_2">{{ item.workCategoryName }}</span>
                          </span>
                          <span class="dw_c_3">{{ item.userNum }}</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col class="t_1_3_2" :span="12">
                      <div class="dw_f t_1_3_2 flexca">
                        <img class="tt_1" src="@/assets/practicalAssessmentSystem/home_2.png" alt="">
                        <div class="tt_2 flexdc flexjc">
                          <div class="tt_2_1">参考人数</div>
                          <div class="tt_2_2">{{ examInformationForTheDay.participantNum }}</div>
                        </div>
                      </div>
                      <div class="dw_c" v-if="examInformationForTheDay.participantNum > 0">
                        <div class="flexcb flexcc" v-for="(item, index) in examInformationForTheDay.participantDetail"
                          :key="index">
                          <span>
                            <span class="dw_c_1 ts_1"></span>
                            <span class="dw_c_2">{{ item.workCategoryName }}</span>
                          </span>
                          <span class="dw_c_3">{{ item.userNum }}</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col class="t_1_3_3" :span="12">
                      <div class="dw_f t_1_3_3 flexca">
                        <img class="tt_1" src="@/assets/practicalAssessmentSystem/home_3.png" alt="">
                        <div class="tt_2 flexdc flexjc">
                          <div class="tt_2_1">合格人数</div>
                          <div class="tt_2_2">{{ examInformationForTheDay.passesNum }}</div>
                        </div>
                      </div>
                      <div class="dw_c" v-if="examInformationForTheDay.passesNum > 0">
                        <div class="flexcb flexcc" v-for="(item, index) in examInformationForTheDay.passesDetail"
                          :key="index">
                          <span>
                            <span class="dw_c_1 ts_1"></span>
                            <span class="dw_c_2">{{ item.workCategoryName }}</span>
                          </span>
                          <span class="dw_c_3">{{ item.userNum }}</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col class="t_1_3_4" :span="12">
                      <div class="dw_f t_1_3_4 flexca">
                        <img class="tt_1" src="@/assets/practicalAssessmentSystem/home_4.png" alt="">
                        <div class="tt_2 flexdc flexjc">
                          <div class="tt_2_1">补考人数</div>
                          <div class="tt_2_2">{{ examInformationForTheDay.makeUpNum }}</div>
                        </div>
                      </div>
                      <div class="dw_c" v-if="examInformationForTheDay.makeUpNum > 0">
                        <div class="flexcb flexcc" v-for="(item, index) in examInformationForTheDay.makeUpDetail"
                          :key="index">
                          <span>
                            <span class="dw_c_1 ts_1"></span>
                            <span class="dw_c_2">{{ item.workCategoryName }}</span>
                          </span>
                          <span class="dw_c_3">{{ item.userNum }}</span>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
            <div class="t_2">
              <div class="t_2_1 df flexca">
                <div class="t_2_1_1 title_1">年度统计数据</div>
                <el-date-picker v-model="value3" type="year" value-format="yyyy" size="small" @change="get_annualStatisticalData" :picker-options="value3pickerOptions"
                  placeholder="选择年" style="width: 120px !important; ">
                </el-date-picker>
              </div>
              <div class="t_2_2 df">
                <div class="flexca">
                  <img class="tt_1" src="@/assets/practicalAssessmentSystem/home2_1.png" alt="">
                  <div class="tt_2 flexdc flexjc">
                    <div class="tt_2_1">考试人数</div>
                    <div class="tt_2_2">{{ value3List.planNum }}</div>
                  </div>
                </div>
                <div class="flexca">
                  <img class="tt_1" src="@/assets/practicalAssessmentSystem/home2_2.png" alt="">
                  <div class="tt_2 flexdc flexjc">
                    <div class="tt_2_1">参考人数</div>
                    <div class="tt_2_2">{{ value3List.participantNum }}</div>
                  </div>
                </div>
                <div class="flexca">
                  <img class="tt_1" src="@/assets/practicalAssessmentSystem/home2_3.png" alt="">
                  <div class="tt_2 flexdc flexjc">
                    <div class="tt_2_1">未参考人数</div>
                    <div class="tt_2_2">{{ value3List.nonParticipantNum }}</div>
                  </div>
                </div>
                <div class="flexca">
                  <img class="tt_1" src="@/assets/practicalAssessmentSystem/home2_4.png" alt="">
                  <div class="tt_2 flexdc flexjc">
                    <div class="tt_2_1">合格人数</div>
                    <div class="tt_2_2">{{ value3List.passesNum }}</div>
                  </div>
                </div>
                <div class="flexca">
                  <img class="tt_1" src="@/assets/practicalAssessmentSystem/home2_5.png" alt="">
                  <div class="tt_2 flexdc flexjc">
                    <div class="tt_2_1">补考人数</div>
                    <div class="tt_2_2">{{ value3List.makeUpNum }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="t_3 flex1">
              <div class="title_1">年度统计数据</div>
              <div class="t_3_2">
                <div id="echarts_5" style="width: 100%; height:100%"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoginRecommend />
  </div>
</template>
<script>
import "echarts-liquidfill/src/liquidFill.js";
export default {
  name: "systemManage_home",
  components: {

  },
  data() {
    return {
      value1: "10", // 考试情况统计 - 年
      list1: [{
        value: '10',
        label: "按年统计"
      }, {
        value: '20',
        label: "按月统计"
      }],
      value2: "10", // 各作业类别合格人数统计 - 年
      list2: [{
        value: '10',
        label: "按年统计"
      }, {
        value: '20',
        label: "按月统计"
      }],
      examInformationForTheDay: {},// 当日考试信息
      value3: String(new Date().getFullYear()), // 年度统计数据 - 年
      value3pickerOptions: {
        disabledDate(time) {
          const currentYear = new Date().getFullYear();
          return time.getFullYear() < 2025 || time.getFullYear() > currentYear;
        }
      },
      value3List: [], // 年度统计数据
    };
  },
  created() {

  },
  mounted() {
    this.get_examSituation();
    this.get_statisticsOfQualifiedPersonnelForEachHomeworkCategory();
    this.get_examInformationForTheDay();
    this.get_annualStatisticalData();
    this.get_monthlyStatisticalData();
  },
  computed: {},
  methods: {
    // 获取 - 考试情况统计
    get_examSituation(val) {
      this.$post("/biz/statistics/ratio", {
        pattern: this.value1
      }, 3000, true, 10)
        .then((res) => {
          let echarts_1 = this.$echarts.init(document.getElementById("echarts_1"));
          let echarts_2 = this.$echarts.init(document.getElementById("echarts_2"));
          let echarts_3 = this.$echarts.init(document.getElementById("echarts_3"));
          this.set_liquidFill(echarts_1, '参考率', res.data.participationRate, '#D9F4FF', '#47D0F2');
          this.set_liquidFill(echarts_2, '合格率', res.data.passesRate, '#DCFAF8', '#16DBCC');
          this.set_liquidFill(echarts_3, '补考率', res.data.makeUpRate, '#FFF5D9', '#FFBB38');
        })
        .catch(() => {
          return;
        });
    },
    // echarts - 水滴图 (dom,名称,背景色,渐变色)
    set_liquidFill(dom, name, num, color_1, color_2) {
      let option = {
        grid: { // 让图表占满容器
          top: "0%",
          left: "0%",
          right: "0%",
          bottom: "0%"
        },
        series: [{
          type: 'liquidFill', // 使用水滴图类型
          data: [num], // 数据值，表示填充程度（0-1之间）
          color: [color_2], // 颜色渐变数组
          backgroundStyle: {
            color: color_1 // 背景颜色
          },
          radius: '80%',
          grid: {
            left: "5%", //图表距边框的距离
            right: "4%",
            bottom: "2%",
            containLabel: true,
          },
          label: {
            normal: {
              formatter: '{c}%\n' + name,
              fontSize: 18,
              color: '#000',
              textStyle: {
                fontSize: 18,
                color: '#000'
              },
            }
          },
          outline: {
            show: false // 是否显示轮廓线，默认为 true，可根据需要调整显示效果
          },
        }],
      };
      dom.setOption(option);
      window.addEventListener("resize", function () {
        dom.resize();
      });
    },
    // 获取 - 各作业类别合格人数统计
    get_statisticsOfQualifiedPersonnelForEachHomeworkCategory() {
      this.$post("/biz/statistics/work/category/passes", {
        pattern: this.value2
      }, 3000, true, 10)
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            res.data[i].value = res.data[i].userNum;
            res.data[i].name = res.data[i].workCategoryName;
          }
          let echarts_4 = this.$echarts.init(document.getElementById("echarts_4"));
          let option = {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              show: false, // 是否显示图例
              bottom: '0',
              left: 'center'
            },
            series: [
              {
                // name: 'Access From',
                type: 'pie',
                radius: ['40%', '65%'],
                center: ['50%', '50%'],
                avoidLabelOverlap: false,
                label: {
                  alignTo: 'edge',
                  // show: false,
                  // position: 'center'
                  formatter: '{b}\n{time|{c}}',
                  rich: {
                    time: {
                      fontSize: 14,
                      color: '#84A1FB'
                    }
                  }
                },
                data: res.data
              }
            ]
          };
          echarts_4.setOption(option);
          window.addEventListener("resize", function () {
            echarts_4.resize();
          });
        })
        .catch(() => {
          return;
        });
    },
    // 获取 - 当日考试信息
    get_examInformationForTheDay() {
      this.$post("/biz/statistics/today/data", {
        pattern: this.value2
      }, 3000, true, 10)
        .then((res) => {
          this.examInformationForTheDay = res.data;
        })
        .catch(() => {
          return;
        });
    },
    // 获取 - 年度统计数据
    get_annualStatisticalData() {
      this.$post("/biz/statistics/year/data", {
        year: this.value3 || String(new Date().getFullYear())
      }, 3000, true, 10)
        .then((res) => {
          this.value3List = res.data;
        })
        .catch(() => {
          return;
        });
    },
    // 获取 - 月统计数据
    get_monthlyStatisticalData() {
      this.$post("/biz/statistics/month/trend", {}, 3000, true, 10)
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            if (i >= 9) {
              res.data[i].unshift(new Date().getFullYear() + '-' + (i + 1));
            } else {
              res.data[i].unshift(new Date().getFullYear() + '-0' + (i + 1));
            }
          }
          res.data = [['product', '考试人数', '参考人数', '未参考人数', '合格人数', '补考人数'], ...res.data];
          let echarts_5 = this.$echarts.init(document.getElementById("echarts_5"));
          let option = {
            legend: {},
            tooltip: {},
            dataset: {
              source: res.data
            },
            xAxis: { type: 'category' },
            yAxis: {},
            series: [{
              type: 'bar',
              itemStyle: {
                normal: {
                  borderRadius: [8, 8, 0, 0],  // 可以根据需要调整这个值
                  color: '#47D0F3'
                }
              }
            }, {
              type: 'bar',
              itemStyle: {
                normal: {
                  borderRadius: [8, 8, 0, 0],  // 可以根据需要调整这个值
                  color: '#84A1FB'
                }
              }
            }, {
              type: 'bar',
              itemStyle: {
                normal: {
                  borderRadius: [8, 8, 0, 0],  // 可以根据需要调整这个值
                  color: '#FF8890'
                }
              }
            }, {
              type: 'bar',
              itemStyle: {
                normal: {
                  borderRadius: [8, 8, 0, 0],  // 可以根据需要调整这个值
                  color: '#16DBCC'
                }
              }
            }, {
              type: 'bar',
              itemStyle: {
                normal: {
                  borderRadius: [8, 8, 0, 0],  // 可以根据需要调整这个值
                  color: '#FFBB38'
                }
              }
            }],
            grid: { // 让图表占满容器
              top: "60px",
              left: "50px",
              right: "30px",
              bottom: "30px"
            }
          };
          echarts_5.setOption(option);
          window.addEventListener("resize", function () {
            echarts_5.resize();
          });
        })
        .catch((err) => {
          console.log(err)
          return;
        });

    },
  }
};
</script>
<style lang="less" scoped>
/deep/ .framePage .framePage-body {
  background: none;
  padding: 0;

  .framePage-scroll {
    padding: 0;

    .ovy-a {
      padding: 0;
    }
  }
}

.title_1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

.t_1 {
  height: 302px;

  >.el-col {
    height: 100%;
    padding: 0 10px;

    .shell {
      height: 100%;

      .sxn {
        background: #fff;
        border-radius: 20px;
        padding: 20px 20px 10px 20px;
      }
    }
  }

  .t_1_3 {
    .shell {
      display: flex;
      flex-direction: column;
    }

    .t_1_3_1 {
      margin-bottom: 3%;
      margin-right: 3%;

      >div {
        background: #D9F4FF;
      }
    }

    .t_1_3_2 {
      margin-bottom: 3%;

      >div {
        background: #E7EDFF;
      }
    }

    .t_1_3_3 {
      margin-right: 3%;

      >div {
        background: #DCFAF8;
      }
    }

    .t_1_3_4 {
      >div {
        background: #FFF5D9;
      }
    }

    .sxn {
      .el-col {
        height: 45%;
        width: 48.5%;
        position: relative;

        .dw_f {
          height: 100%;
          border-radius: 16px;
          cursor: pointer;

          .tt_1 {
            width: 60px;
            height: 60px;
            margin-right: 10px;
            margin-left: 20px;
          }

          .tt_2 {
            .tt_2_1 {
              color: #666666;
              margin-bottom: 10px;
            }

            .tt_2_2 {
              font-size: 20px;
              font-weight: 600;
            }
          }
        }

        .dw_c {
          display: none;
          position: absolute;
          z-index: 2;
          right: 8px;
          top: 80px;
          padding: 10px;
          border-radius: 8px;
          background: #fff;
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.12);

          >div {
            margin-bottom: 6px;

            .ts_1 {
              background: #D9F4FF;
            }

            .ts_2 {
              background: #E7EDFF;
            }

            .ts_3 {
              background: #DCFAF8;
            }

            .ts_4 {
              background: #FFF5D9;
            }

            .dw_c_1 {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              margin-right: 6px;
            }

            .dw_c_2 {
              color: #666666;
            }

            .dw_c_3 {
              margin-left: 20px;
              font-size: 18px;
              font-weight: 600;
            }
          }
        }

        &:hover {
          .dw_c {
            display: block;
          }
        }
      }
    }
  }
}

.t_2 {
  .t_2_1 {
    .t_2_1_1 {
      margin-right: 10px;
    }
  }

  .t_2_2 {
    >div {
      width: 20%;
      margin-right: 20px;
      background: #fff;
      border-radius: 20px;
      padding: 20px 10px;

      &:last-child {
        margin-right: 0;
      }

      .tt_1 {
        width: 60px;
        height: 60px;
        margin-right: 10px;
        margin-left: 20px;
      }

      .tt_2 {
        .tt_2_1 {
          color: #666666;
          margin-bottom: 10px;
        }

        .tt_2_2 {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }
}

.t_3 {
  .t_3_2 {
    background: #fff;
    height: calc(100% - 80px);
    padding-top: 20px;
  }
}
</style>